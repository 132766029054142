'use strict';

var initialize = function() {
	var minWidth;
	var maxWidth;
	var padding;

	if ($(window).width() > 767) {
		minWidth = 600;
		maxWidth = 600;
	} else if ($(window).width() > 425) {
		minWidth = '100%';
		maxWidth = '100%';
		padding = [10, 28, 10, 28];
	} else {
		minWidth = '100%';
		maxWidth = '100%';
		padding = [10, 28, 10, 28];
	}

	$('.cassidy-ai').fancybox({
		'scrolling': 'auto',
		'minWidth': minWidth,
		'maxWidth': maxWidth,
		'padding': padding,
		'minHeight': 600,
		'closeBtn': false,
		'autoHeight': true,
		'autoResize': true,
		afterShow: function() {
			fetchCassidy();
		}
	});

	$(document).on('click', '.fancybox-close-link', function(e) {
		e.preventDefault();
		$.fancybox.close();
	});
}

var fetchCassidy = async function () {

	var itemDesc = getItemDescriptionContent();

	// Ex of itemDesc:
	// itemDesc = "name: CODY JAMES BLACK 1978® MEN'S CHAPMAN EXOTIC CAIMAN BELLY WESTERN BOOTS - MEDIUM TOE, item details: Durable genuine caiman belly vamp with genuine calfskin shaft Hand laid cording detail on shaft 1.75\" stacked leather western heel for long lasting quality, comfort and durability Premium cowhide lining in top and vamp Single-stitched 3/4 channel welt construction for a low profile look Pull-on style with pull tabs Leather outsole with rubber heel cap 12\" shaft height Solid cognac design with leather side and top piping Handcrafted by highly skilled boot artisans, reviews: 4.5";

	var div = document.getElementById("cassidy-modal-text");
	var url = Urls.CassidyAIUrl;
	var assistant_id = SitePreferences.CASSIDY_ASSISTANT_ID;
	var messages = new Array();
	var message = {
		role: "user",
		content: itemDesc
	};
	messages.push(message);

	var response = await fetch(url, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'OpenAI-Beta': 'assistants=v2'
		},
		body: JSON.stringify({
			assistant_id: assistant_id,
			thread: {
				messages: messages
			},
			stream: true
		})
	});

	if (response.ok) {

		div.innerHTML = '';

		// Process the chunks
		var reader = response.body.getReader();
		reader.read().then(function chunk({done, value}) {
			var delta = new TextDecoder().decode(value);
			if (done) {
				return;
			}
			processStream(delta, div);

			return reader.read().then(chunk);
		});
	} else {
		// Default error message
		var errorMessage = "Hi there! <br /><br />" +
						"I'm having trouble getting information about this product right now. Please try again later.";
		div.innerHTML = errorMessage;
	}

}

/* Private Functions */
var getItemDescriptionContent = function() {
	var productName = $('#cassidy-product-name')[0].innerText.trim();
	var reviews = $('.rating-container .avg-rating')[0];
	var productDetails = $('.product-features .product-info')[0];

	var itemDesc = "name: " + productName;

	if (productDetails) {
		var productDetailsText = $('#cassidy-product-description')[0].innerText.trim();
		itemDesc += ", item details: " + productDetailsText;
	}

	if (reviews) {
		var avgRating = reviews.innerText;
		itemDesc += ", reviews: " + avgRating;
	}

	return itemDesc;
}

var processStream = function(delta, div) {

	// Cleanup string for json
	var regex = /^event:.*\sdata:./gim;
	// console.log(delta);

	// Last event is "[DONE]"
	if (!delta.includes("DONE")) {

		// A single chunk can contain multiple event lines
		var lines = delta.split(regex);

		try {
			lines.forEach((function(line) {
				// console.log(line);
				if (line !== "") {
					var data = JSON.parse(line);
					//console.log(data);
					if (data.object == "thread.message.delta") {
						var text = data.delta.content[0].text.value;
						text = text.replace('\n\n', '<br /><br />');
						div.innerHTML += text;
					}
				}
			}));

		}
		catch (err) {
			console.log(err);
			// console.log("Failed: " + delta);
		}
	}

}

exports.FetchCassidy = fetchCassidy;
exports.init = initialize;

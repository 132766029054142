'use strict';

var CassidyAIHelper = require('../../../../app_storefront_core/cartridge/js/helpers/CassidyAIHelper');

var sheplersApp = {
    init: function () {

    }
};

//initialize app
$(document).ready(function () {
    sheplersApp.init();
    	CassidyAIHelper.init();
});